<template>
  <div>
    <div class="p-d-flex p-ac-around p-p-3">
      <img
        width="50px"
        height="50px"
        style="
          object-fit: contain;
          cursor: pointer;
          -webkit-filter: drop-shadow(1px 1px 7px #000);
          filter: drop-shadow(1px 1px 2px #000);
        "
        :src="require('@/assets/bogorpools.png')"
      />
      <div class="p-jc-start p-ml-2">
        <div class="hf2 p-text-bold gentium">WINNING RESULTS</div>
        <div class="gentium p-mt-0">
          Draw No :
          {{ draw_no + " | " + draw_date + " (" + getDay(draw_date) + ")" }}
        </div>
      </div>
    </div>
    <div v-if="waktu != null">
      <!--1st prize-->
      <div class="p-grid p-jc-start p-mt-3">
        <div class="hf1 orange p-col p-text-center">1st prize</div>
        <div v-if="!acak" class="hf1 p-text-bold p-col p-text-center">
          {{ draw_prize1 }}
        </div>
        <div v-else class="hf1 p-text-bold p-col p-text-center">
          <RandomSix
            :waktu="getWaktuDetik(waktu.prize1_result_end, 0)"
            :nilai="draw_prize1"
          />
        </div>
      </div>
      <div
        style="
          background-color: rgba(255, 255, 255, 0.8);
          height: 1px;
          width: 100%;
        "
      />
      <!--End of 1st prize-->

      <!--2nd prize-->
      <div class="p-grid p-jc-start p-mt-3">
        <div class="hf1 orange p-col p-text-center">2nd prize</div>
        <div v-if="!acak" class="hf1 p-text-bold p-col p-text-center">
          {{ draw_prize2 }}
        </div>
        <div v-else class="hf1 p-text-bold p-col p-text-center">
          <RandomSix
            :waktu="getWaktuDetik(waktu.prize2_result_end, 0)"
            :nilai="draw_prize2"
          />
        </div>
      </div>
      <div
        style="
          background-color: rgba(255, 255, 255, 0.4);
          height: 1px;
          width: 100%;
        "
      />
      <!--End of 2nd prize-->

      <!--3rd prize-->
      <div class="p-grid p-jc-start p-mt-3">
        <div class="hf1 orange p-col p-text-center">3rd prize</div>
        <div v-if="!acak" class="hf1 p-text-bold p-col p-text-center">
          {{ draw_prize3 }}
        </div>
        <div v-else class="hf1 p-text-bold p-col p-text-center">
          <RandomSix
            :waktu="getWaktuDetik(waktu.prize3_result_end, 0)"
            :nilai="draw_prize3"
          />
        </div>
      </div>
      <div
        style="
          background-color: rgba(255, 255, 255, 0.1);
          height: 2px;
          width: 100%;
        "
      />
      <!--End of 3rd prize-->

      <!--special & consolation-->
      <div class="p-grid p-mt-1">
        <!--Special-->
        <div
          class="p-col p-text-center p-text-bold"
          style="border-right: rgba(255, 255, 255, 0.1) solid 2px"
        >
          <div class="hf1 orange">Special Prize</div>
          <div v-if="!acak">
            <div>{{ draw_started1 }}</div>
            <div>{{ draw_started2 }}</div>
            <div>{{ draw_started3 }}</div>
            <div>{{ draw_started4 }}</div>
            <div>{{ draw_started5 }}</div>
            <div>{{ draw_started6 }}</div>
          </div>
          <div v-else>
            <RandomSix
              :waktu="getWaktuDetik(waktu.started_result_end, 0)"
              :nilai="draw_started1"
            />
            <RandomSix
              :waktu="getWaktuDetik(waktu.started_result_end, 120)"
              :nilai="draw_started2"
            />
            <RandomSix
              :waktu="getWaktuDetik(waktu.started_result_end, 240)"
              :nilai="draw_started3"
            />
            <RandomSix
              :waktu="getWaktuDetik(waktu.started_result_end, 360)"
              :nilai="draw_started4"
            />
            <RandomSix
              :waktu="getWaktuDetik(waktu.started_result_end, 480)"
              :nilai="draw_started5"
            />
            <RandomSix
              :waktu="getWaktuDetik(waktu.started_result_end, 600)"
              :nilai="draw_started6"
            />
          </div>
        </div>
        <!--consolation-->
        <div class="p-col p-text-center p-text-bold">
          <div class="hf1 orange">Consolation Prize</div>
          <div v-if="!acak">
            <div>{{ draw_consolation1 }}</div>
            <div>{{ draw_consolation2 }}</div>
            <div>{{ draw_consolation3 }}</div>
            <div>{{ draw_consolation4 }}</div>
            <div>{{ draw_consolation5 }}</div>
            <div>{{ draw_consolation6 }}</div>
          </div>
          <div v-else>
            <RandomSix
              :waktu="getWaktuDetik(waktu.consolation_result_end, 0)"
              :nilai="draw_consolation1"
            />
            <RandomSix
              :waktu="getWaktuDetik(waktu.consolation_result_end, 120)"
              :nilai="draw_consolation2"
            />
            <RandomSix
              :waktu="getWaktuDetik(waktu.consolation_result_end, 240)"
              :nilai="draw_consolation3"
            />
            <RandomSix
              :waktu="getWaktuDetik(waktu.consolation_result_end, 360)"
              :nilai="draw_consolation4"
            />
            <RandomSix
              :waktu="getWaktuDetik(waktu.consolation_result_end, 480)"
              :nilai="draw_consolation5"
            />
            <RandomSix
              :waktu="getWaktuDetik(waktu.consolation_result_end, 600)"
              :nilai="draw_consolation6"
            />
          </div>
        </div>
      </div>
      <!--special & consolation-->
    </div>
    <div class="p-fluid boxPayment p-p-2 p-text-center p-mt-2 p-shadow-1">
      Full Payment Guaranteed
    </div>
  </div>
</template>

<script>
import RandomSix from "./items/RandomSix.vue";
export default {
  components: {
    RandomSix,
  },
  data() {
    return {
      draw_data: null,
      draw_no: "",
      draw_date: "",
      draw_prize1: "",
      draw_prize2: "",
      draw_prize3: "",
      draw_started1: "",
      draw_started2: "",
      draw_started3: "",
      draw_started4: "",
      draw_started5: "",
      draw_started6: "",
      draw_consolation1: "",
      draw_consolation2: "",
      draw_consolation3: "",
      draw_consolation4: "",
      draw_consolation5: "",
      draw_consolation6: "",
      waktu: null,
      web_data: null,
      show_prize1: false,
      show_prize2: false,
      show_prize3: false,
      show_special: false,
      show_consolation: false,
      timer_standby_end: null,
      timer_result_end: null,
      acak: this.$store.getters.getAcak,
      skrg: new Date(),
    };
  },
  computed: {},
  destroyed() {
    clearInterval(this.timer_standby_end);
  },
  methods: {
    setIntervalUpdate() {
      this.cekJam();
      // var sekarang = new Date();
      // sekarang.setHours(0,0,0,0)
      // var draw_date = new Date(this.draw_data[0].draw_date);
      // draw_date.setHours(0,0,0,0)
      // if (draw_date == sekarang) {
      //   this.interval_jam = setInterval(() => {
      //     this.cekJam();
      //   }, 1000);
      // }else{
      //   this.show_consolation = true;
      //   this.show_special = true;
      //   this.show_prize3 = true;
      //   this.show_prize2 = true;
      //   this.show_prize1 = true;
      // }
    },
    getWaktuDetik(data, offset) {
      // data = "01:26:00";
      var h = data.substring(0, 2);
      var m = data.substring(3, 5);
      var s = data.substring(6, 8);
      var waktu = new Date();
      var sekarang = new Date();
      waktu.setHours(h);
      waktu.setMinutes(m);
      waktu.setSeconds(s - offset);

      //testing summy
      // data = "22:59:50";
      // h = data.substring(0, 2);
      // m = data.substring(3, 5);
      // s = data.substring(6, 8);
      // sekarang.setHours(h);
      // sekarang.setMinutes(m);
      // sekarang.setSeconds(s);
      var countdown = waktu.getTime() - sekarang.getTime();
      return countdown;
    },
    cekJam() {
      var sekarang = new Date().toLocaleTimeString();
      if (sekarang > this.waktu.started_result_end) {
        this.show_consolation = true;
      } else {
        this.show_consolation = false;
      }

      if (sekarang > this.waktu.started_result_end) {
        this.show_special = true;
      } else {
        this.show_special = false;
      }

      if (sekarang > this.waktu.prize3_result_end) {
        this.show_prize3 = true;
      } else {
        this.show_prize3 = false;
      }

      if (sekarang > this.waktu.prize2_result_end) {
        this.show_prize2 = true;
      } else {
        this.show_prize2 = false;
      }

      if (sekarang > this.waktu.prize1_result_end) {
        this.show_prize1 = true;
      } else {
        this.show_prize1 = false;
      }
    },
    async getWebContent() {
      await this.$http.get("/web").then((r) => (this.web_data = r.data));
    },
    async getWebTime() {
      // await this.$http.get("/time").then((r) => {
      this.waktu = this.$store.state.waktu;
      // this.dummy();
      // this.setIntervalUpdate();
      // });
    },
    async getResult() {
      await this.$http.get("/result").then((r) => {
        this.draw_data = r.data;

        // this.waktu.standby_end="21:55:30"
        var data = this.waktu.close_end;
        var h = data.substring(0, 2);
        var m = data.substring(3, 5);
        var s = data.substring(6, 8);
        var waktu = new Date();
        var resultEnd = new Date();
        var sekarang = new Date();
        waktu.setHours(h);
        waktu.setMinutes(m);
        waktu.setSeconds(s);

        //set open_end
        data = this.waktu.open_end;
        h = data.substring(0, 2);
        m = data.substring(3, 5);
        s = data.substring(6, 8);
        resultEnd.setHours(h);
        resultEnd.setMinutes(m);
        resultEnd.setSeconds(s);

        // waktu.setDate(waktu.getDate() - 1);
        // sekarang.setDate(sekarang.getDate() - 1);

        //test dummy
        // data = "21:29:55";
        // h = data.substring(0, 2);
        // m = data.substring(3, 5);
        // s = data.substring(6, 8);
        // sekarang.setHours(h);
        // sekarang.setMinutes(m);
        // sekarang.setSeconds(s);
        this.stopAcak(waktu.getTime() - sekarang.getTime());
        if (
          waktu.toDateString() == new Date(r.data[0].draw_date).toDateString()
        ) {
          if (sekarang.getTime() < resultEnd.getTime()) {
            // console.log(waktu.toString())
            // console.log(sekarang.toString())
            this.setResult(this.draw_data[1]);
            this.$store.commit("setAcak", false);
            this.startAcak(
              resultEnd.getTime()-sekarang.getTime(),
              this.draw_data[0]
            );
          } else {
            // console.log("2")
            this.setResult(this.draw_data[0]);
            this.$store.commit("setAcak", true);
          }
        } else if (this.cekDateBigger(waktu, new Date(r.data[0].draw_date))) {
          // console.log("3")
          this.setResult(this.draw_data[0]);
          this.$store.commit("setAcak", false);
        }
      });
    },
    cekDateBigger(nowDate, drawDate) {
      var a = nowDate.getDate() + nowDate.getMonth() + nowDate.getFullYear();
      var b = drawDate.getDate() + drawDate.getMonth() + drawDate.getFullYear();
      if (a > b) {
        return true;
      }
      return false;
    },
    getDay(tanggal) {
      var hari = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      var dt = new Date(tanggal);
      return hari[dt.getDay()];
    },
    setResult(data) {
      this.draw_no = data.draw_no;
      this.draw_date = data.draw_date;
      this.draw_prize1 = data.draw_prize1;
      this.draw_prize2 = data.draw_prize2;
      this.draw_prize3 = data.draw_prize3;
      this.draw_started1 = data.draw_started1;
      this.draw_started2 = data.draw_started2;
      this.draw_started3 = data.draw_started3;
      this.draw_started4 = data.draw_started4;
      this.draw_started5 = data.draw_started5;
      this.draw_started6 = data.draw_started6;
      this.draw_consolation1 = data.draw_consolation1;
      this.draw_consolation2 = data.draw_consolation2;
      this.draw_consolation3 = data.draw_consolation3;
      this.draw_consolation4 = data.draw_consolation4;
      this.draw_consolation5 = data.draw_consolation5;
      this.draw_consolation6 = data.draw_consolation6;
    },
    dummy() {
      this.waktu.consolation_result_end = "21:42:00";
      this.waktu.started_result_end = "21:32:30";
      this.waktu.prize3_result_end = "01:43:05";
      this.waktu.prize2_result_end = "01:43:12";
      this.waktu.prize1_result_end = "01:43:19";
    },
    startAcak(countdown, data) {
      console.log("coutn",countdown)
      this.timer_standby_end = setInterval(() => {
        console.log(countdown)
        if (countdown < 1000) {
          this.$store.commit("setAcak", true);
          this.setResult(data);
          clearInterval(this.timer_standby_end);
        }
        countdown -= 1000;
      }, 1000);
    },
    stopAcak(countdown) {
      this.timer_result_end = setInterval(() => {
        if (countdown < 1) {
          this.$store.commit("setAcak", false);
          clearInterval(this.timer_result_end);
        }
        countdown -= 1;
      }, 1);
    },
  },
  async mounted() {
    await this.getWebTime();
    await this.getResult();
  },
  watch: {
    "$store.state.acak": function (val) {
      this.acak = val;
    },
  },
};
</script>