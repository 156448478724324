<template>
  <div style="color: white" class="liveBg p-p-0 p-m-0 p-shadow-6">
    <div class="p-md-offset-3 p-col-12 p-md-6">
      <div
        class="p-mt-4 p-p-2 box p-shadow-4"
        v-if="waktu != null && web_data != null"
      >
        <div class="p-text-center">
          Livedraw will start on {{ waktu.standby_end }}
        </div>
        <div class="p-text-center">
          {{ web_data.web_timezone + " ( " + web_data.web_gmt + " )" }}
        </div>
      </div>
      <div class="p-mt-4 p-p-2 box p-shadow-4" v-else>
        <Skeleton class="p-mb-2"></Skeleton>
        <Skeleton class="p-mb-2"></Skeleton>
        <Skeleton width="5rem" class="p-mb-2"></Skeleton>
      </div>
      <client-running />
    </div>
  </div>
</template>


<script>
import ClientRunning from "./ClientRunning.vue";
export default {
  components: {
    ClientRunning,
  },
  data() {
    return {
      draw_data: null,
      draw_no: "",
      draw_date: "",
      draw_prize1: "",
      draw_prize2: "",
      draw_prize3: "",
      draw_started1: "",
      draw_started2: "",
      draw_started3: "",
      draw_started4: "",
      draw_started5: "",
      draw_started6: "",
      draw_consolation1: "",
      draw_consolation2: "",
      draw_consolation3: "",
      draw_consolation4: "",
      draw_consolation5: "",
      draw_consolation6: "",
      waktu: null,
      web_data: null,
      show_prize1: false,
      show_prize2: false,
      show_prize3: false,
      show_special: false,
      show_consolation: false,
    };
  },
  computed: {},
  methods: {
    async getWebContent() {
      await this.$http.get("/web").then((r) => (this.web_data = r.data));
    },
    getWebTime() {
      this.$http.get("/time").then((r) => {
        this.waktu = r.data;
      });
    },
    async getResult() {
      await this.$http.get("/result").then((r) => {
        this.draw_data = r.data;
        this.setResult(r.data[0]);
      });
    },
    getDay(tanggal) {
      var hari = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      var dt = new Date(tanggal);
      return hari[dt.getDay()];
    },
    setResult(data) {
      this.draw_no = data.draw_no;
      this.draw_date = data.draw_date;
      this.draw_prize1 = data.draw_prize1;
      this.draw_prize2 = data.draw_prize2;
      this.draw_prize3 = data.draw_prize3;
      this.draw_started1 = data.draw_started1;
      this.draw_started2 = data.draw_started2;
      this.draw_started3 = data.draw_started3;
      this.draw_started4 = data.draw_started4;
      this.draw_started5 = data.draw_started5;
      this.draw_started6 = data.draw_started6;
      this.draw_consolation1 = data.draw_consolation1;
      this.draw_consolation2 = data.draw_consolation2;
      this.draw_consolation3 = data.draw_consolation3;
      this.draw_consolation4 = data.draw_consolation4;
      this.draw_consolation5 = data.draw_consolation5;
      this.draw_consolation6 = data.draw_consolation6;
    },
    dummy() {
      this.waktu.consolation_result_end = "21:42:00";
      this.waktu.started_result_end = "21:32:30";
      this.waktu.prize3_result_end = "01:43:05";
      this.waktu.prize2_result_end = "01:43:12";
      this.waktu.prize1_result_end = "01:43:19";
    },
  },
  async mounted() {
    this.getWebTime();
    this.getWebContent();
  },
};
</script>
