<template>
  <div class="p-d-flex p-jc-center">
    <slot-number :stop="stop1" :nilaiAsli="nilai.charAt(0)" />
    <slot-number :stop="stop2" :nilaiAsli="nilai.charAt(1)" />
    <slot-number :stop="stop3" :nilaiAsli="nilai.charAt(2)" />
    <slot-number :stop="stop4" :nilaiAsli="nilai.charAt(3)" />
    <slot-number :stop="stop5" :nilaiAsli="nilai.charAt(4)" />
    <slot-number :stop="stop6" :nilaiAsli="nilai.charAt(5)" />
  </div>
</template>

<script>
import SlotNumber from "./SlotNumber.vue";
export default {
  data() {
    return {
      stop1: false,
      stop2: false,
      stop3: false,
      stop4: false,
      stop5: false,
      stop6: false,
      interval: null,
      waktuChild: 0,
    };
  },
  props: { waktu: Number, nilai: String },
  components: { SlotNumber },
  methods: {
    divideWaktu() {
      this.interval = setInterval(() => {
        this.waktuChild -= 1;
        if (this.waktuChild <= 100) {
          this.stop6 = true;
        }
        if (this.waktuChild <= 80) {
          this.stop5 = true;
        }
        if (this.waktuChild <= 60) {
          this.stop4 = true;
        }
        if (this.waktuChild <= 40) {
          this.stop3 = true;
        }
        if (this.waktuChild <= 20) {
          this.stop2 = true;
        }
        if (this.waktuChild <= 0) {
          this.stop1 = true;
          clearInterval(this.interval);
        }
      }, 1000);
    },
  },
  mounted() {
    this.waktuChild = this.waktu;
    this.divideWaktu();
  },
  destroyed() {
    clearInterval(this.interval);
  },
};
</script>

<style>
</style>