<template>
  <div>{{ nilai }}</div>
</template>

<script>
export default {
  props: {
    nilaiAsli: String,
    stop: Boolean,
  },
  data() {
    return {
      nilai: null,
      intv: null,
    };
  },
  watch: {
    stop: {
      immediate: true,
      handler(val) {
        if (val) {
          clearInterval(this.intv);
          this.nilai = this.nilaiAsli;
        }
      },
    },
  },
  methods: {
    randomNumber() {
      var rnd = Math.floor(Math.random() * 9).toString();
      this.nilai = rnd;
    },
  },
  mounted() {
    if (!this.stop) {
      this.intv = setInterval(() => {
        this.randomNumber();
      }, 100);
    } else {
      this.nilai = this.nilaiAsli;
    }
  },
  destroyed() {
    clearInterval(this.intv);
  },
};
</script>

<style>
</style>